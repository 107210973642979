import React from 'react'
import styled from 'styled-components'
import tachyons from 'styled-components-tachyons'
import LogoWithoutLabel from '../../../static/assets/img/logo-mark.svg'

const Svg = styled.img`
  width: 23px;
  height: 27px;
  height: 100% !important;
  ${tachyons};

  @media (min-width: 800px) {
  height: 100% !important;
    width: 34px !important;
    height: 40px !important;
  }
`

const Logo = props => (
  <Svg
    src={LogoWithoutLabel}
    {...props}
    alt="Unroll.Me - Block emails, instantly"
  />
)

export default Logo
